/* eslint-disable global-require */
import React from "react"
import { Container } from "react-grid-system"
import PropTypes from "prop-types"
import styled, { ThemeConsumer } from "styled-components"
import ProcessItem from "../components/process/ProcessItem"
import SEO from "../components/seo"
import { Header1 } from "../elements"
import { rhythm } from "../../config/typography"
import anim1 from "../components/lottie/processDiscover.json"
import anim2 from "../components/lottie/processDesign.json"
import anim3 from "../components/lottie/processDeliver.json"
import ServiceCard from "../components/process/ServiceCard"
import TransitionContext from "../context/TransitionContext"

const items = [
  {
    count: `1`,
    name: `Discover`,
    secondary: false,
    anim: anim2,
    descr: `At PAKD, every project starts with defining your unique positioning, target audience, and essential ambitions. We leverage this understanding to develop a design system that's flexible, scalable, and ready for success. Using your project's core message as our decision-making cornerstone, we capitalize on what you do best to ensure an outcome that drives results.`,
  },
  {
    count: `2`,
    name: `Develop`,
    secondary: true,
    anim: anim1,
    descr: `
    With a cohesive foundation laid, we begin developing creative solutions tailored to you. We create several iterations for analysis, collaborate with you and your team to narrow our direction, and then refine to completion. This method ensures the final project deliverables create excitement, trust, and recognition for your project.`,
  },
  {
    count: `3`,
    name: `Deliver`,
    secondary: false,
    anim: anim3,
    descr: `It's not enough to create stunning collateral. Every project is an opportunity to measure results that inform future decisions. Our team will work with you to analyze results, consult on future solutions, and continue to nurture your design maturity. With PAKD, you don't just get a vendor; you get a partner continually working to extend your team's creative strategies. `,
  },
]
const ProcessPage = ({ location }) => (
  <>
    <SEO
      title="Services & Process"
      pathname={location.pathname}
      description="Discover PAKD Media's creative services and industry leading process."
    />
    <TransitionContext location={location}>
      <Container
        fluid
        component={ProcessContainer}
        className="pagepadtop tilebg"
      >
        <div className="  padtopsm innerwrap">
          <Header1
            lineOne="Process"
            lineTwo="How we deliver value."
            marginbottom
          />
        </div>
        <SubWrap>
          <ProcessItems />
        </SubWrap>
        <ServWrap>
          <Header1
            lineOne="Services"
            lineTwo="Backed by an industry leading process."
          />
          <ThemeConsumer>
            {theme => <ServiceCard theme={theme} />}
          </ThemeConsumer>
        </ServWrap>
      </Container>
    </TransitionContext>
  </>
)

ProcessPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

const ProcessItems = () =>
  items.map(item => (
    <div key={item.name}>
      <ProcessItem
        count={`NO.${item.count}`}
        heroCount={`0${item.count}`}
        name={item.name}
        descr={item.descr}
        animData={item.anim}
        wpOffset="50%"
        target={item.target}
        secondary={item.secondary}
      />
    </div>
  ))

ProcessItems.propTypes = {
  item: PropTypes.shape({
    animData: PropTypes.isRequired,
    count: PropTypes.string.isRequired,
    descr: PropTypes.string.isRequired,
    heroCount: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    secondary: PropTypes.bool.isRequired,
    wpOffset: PropTypes.string.isRequired,
  }),
}

const ProcessContainer = styled.div`
  background-color: #f3f3f2;
  z-index: -1;
  position: relative;
  overflow: hidden;
  margin: 0;
`

const SubWrap = styled.div`
  padding-right: ${rhythm(1)} !important;
  padding-left: ${rhythm(1)} !important;
`
const ServWrap = styled.div`
  position: relative;
  z-index: 100000;
  padding-bottom: ${rhythm(2)};
`

export default ProcessPage
