import React from "react"
import { Row, Col } from "react-grid-system"
import styled from "styled-components"
import ProcessWidget from "../lottie/ProcessWidget"
import Phase from "./Phase"
import PhaseDescr from "./PhaseDescr"
import { rhythm } from "../../../config/typography"

const ProcessItem = ({
  animData,
  count,
  descr,
  heroCount,
  name,
  secondary,
  wpOffset,
}) => (
  <Wrap className="innerwrap">
    <Row key={name} align="center" justify={secondary ? `end` : `start`}>
      <Col md={6} sm={8} xs={12}>
        <Phase
          count={count}
          name={name}
          heroCount={heroCount}
          secondary={secondary}
          wpOffset={wpOffset}
        />
      </Col>
    </Row>
    <Row justify={secondary ? `start` : `end`}>
      <Col md={10} sm={10} xs={12}>
        <PhaseDescr secondary={secondary} wpOffset={wpOffset}>
          <p style={{ maxWidth: `50ch`, margin: `0 auto` }}>{descr}</p>
        </PhaseDescr>
      </Col>
    </Row>
    <Row align="center" justify="center">
      <Col
        xs="content"
        style={{ position: `relative`, margin: `auto !important` }}
      >
        <ProcessWidget
          animationData={animData}
          wpOffset={wpOffset}
          secondary={secondary}
        />
      </Col>
    </Row>
  </Wrap>
)

export default ProcessItem

const Wrap = styled.div`
  position: relative;
  margin-bottom: ${rhythm(1)};
  z-index: 1000;
`
