import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"

import { rhythm } from "../../../config/typography"

const items = [
  {
    name: ` Digital`,
    count: `01`,
    subtitle: `A foundation for leading brands.`,
    bgColor: `transparent`,
    list: [
      `3D Animation + VFX`,
      `Data Visualization`,
      `Motion Graphics`,
      `UI/UX Design`,
      `Video Production`,
      `Web Design`,
    ],
    secondary: 0,
    descr: `We develop branded collateral within a number of creative principles. Getting your brand’s visual identity, messaging and tone cohesive across your marketing channels is a must to help drive growth.`,
    service: true,
  },
  {
    name: `Branding`,
    count: `02`,
    subtitle: `It's your story. Let's tell it right.`,
    bgColor: `rgb(17, 24, 31)`,
    list: [
      `Brand Guides`,
      `Design Systems`,
      `Logo + Brandmark`,
      `Narrative + Tone`,
      `Strategy`,
      `Visual Identity`,
    ],
    secondary: 1,
    descr: `We develop branded collateral within a number of creative principles. Getting your brand’s visual identity, messaging and tone cohesive across your marketing channels is a must to help drive growth.`,
    service: true,
  },
  {
    name: `Collateral`,
    count: `03`,
    subtitle: `Some witty subtitle. I'm out of ideas.`,
    bgColor: `transparent`,
    list1: `Packaging`,
    list2: `Print Layout`,
    list3: `Infographics`,
    list4: `Presentations`,
    list5: `Typography`,
    list6: `Garment`,
    list: [
      `Deck Design`,
      `Garment Design`,
      `Infographics`,
      `Packaging`,
      `Print Layout`,
      `Typography`,
    ],
    secondary: 0,
    descr: `We develop branded collateral within a number of creative principles. Getting your brand’s visual identity, messaging and tone cohesive across your marketing channels is a must to help drive growth.`,
    service: true,
  },
]

const ServiceCard = () => (
  <div className="innerwrap">
    <Container>
      <Row align="center" justify="center">
        {items.map(item => (
          <Col
            sm={4}
            xs={12}
            key={item.name}
            style={{ padding: `${rhythm(1 / 2)} 0` }}
          >
            <ServTitle>{item.name}</ServTitle>
            <ServicesList className="servlist" listItems={item.list} />
          </Col>
        ))}
      </Row>
      <ServicesParagraph>
        <p
          css={`
            margin-top: ${rhythm(2)};
            &:before {
              content: "";
              display: inline-block;
              height: 1px;
              width: 100%;
              margin-bottom: ${rhythm(1)};
              background-color: ${props => props.theme.colors.midgrey};
            }
          `}
        >
          PAKD is a team of multi-disciplined designers, animators, and
          developers who you can rely on as partners in all your creative
          endeavors. Not just logo-designing magicians or motion graphics
          wizards, but creative problem-solvers who require consideration,
          logistics, and craft.
        </p>
      </ServicesParagraph>
      <ServicesParagraph>
        <p
          css={`
            &:after {
              content: "";
              display: inline-block;
              height: 1px;
              width: 100%;
              margin-top: ${rhythm(1.5)};
              background-color: ${props => props.theme.colors.midgrey};
            }
          `}
        >
          Our focus on the process provides you with an efficient approach
          backed by years of experience in the marketplace, no matter the
          vertical or project.
        </p>
      </ServicesParagraph>
    </Container>
  </div>
)

const ServicesParagraph = ({ children }) => (
  <Row justify="center" align="center">
    <Col xs={8}>{children}</Col>
  </Row>
)

const ServicesList = ({ listItems }) => (
  <React.Fragment>
    {listItems.map(listItem => (
      <Row key={listItem}>
        <Col xs={12}>
          <ServList>
            <li>
              <h5>{listItem}</h5>
            </li>
          </ServList>
        </Col>
      </Row>
    ))}
  </React.Fragment>
)

const ServTitle = styled.h3`
  text-align: left;
  font-weight: 400;
  text-align: center;

  margin-bottom: ${rhythm(1 / 2)};
  color: ${props => props.theme.brand.primarylite};
`
const ServList = styled.ul`
  list-style: none;
  margin: auto;
  text-align: center;
  h5 {
    margin: 0;
  }
  p {
    margin: 0 !important;
    color: ${props => props.theme.colors.darkgrey};
  }
`

export default ServiceCard
