import React from "react"
import LottieInView from "./LottieInView"

const ProcessWidget = ({ animationData, id }) => (
  <React.Fragment>
    <LottieInView
      animationData={animationData}
      id={id}
      speed={0.66}
      width="100%"
      height="100%"
      threshold={1}
      inViewTimeout={200}
    />
  </React.Fragment>
)

export default ProcessWidget
