import React from "react"
// import PropTypes from 'prop-types';
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"
import { useInView } from "react-intersection-observer"
import { rhythm } from "../../../config/typography"

const PhaseDescr = ({ secondary, children }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
    triggerOnce: true,
  })

  const spring = useSpring({
    from: { opacity: 0, transform: `translate3d(100px,0,0)` },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? `translate3d(0,0,0)` : `translate3d( 100px, 0, 0)`,
    },
    config: config.slow,
  })
  const spring2 = useSpring({
    from: { opacity: 0, transform: `translate3d(-100px,0,0)` },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? `translate3d(0,0,0)` : `translate3d( -100px, 0, 0)`,
    },
    config: config.slow,
  })

  let pWrap
  if (secondary) {
    pWrap = <PWrap style={spring}>{children}</PWrap>
  } else {
    pWrap = <PWrap style={spring2}>{children}</PWrap>
  }
  return (
    <React.Fragment>
      <span ref={ref} />
      {pWrap}
    </React.Fragment>
  )
}

export default PhaseDescr

const PWrap = styled(animated.div)`
  z-index: 5;
  line-height: ${rhythm(1.2)};
  p {
    padding-bottom: ${rhythm(2)};
  }
`
