import React from "react"
import styled, { css } from "styled-components"
import { useSpring, animated } from "react-spring"
import { useInView } from "react-intersection-observer"
import PropTypes from "prop-types"
import { below } from "../../../config/utilities"
import { rhythm } from "../../../config/typography"

const Phase = ({ count, heroCount, name, secondary, tertiary }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 1,
    triggerOnce: true,
  })

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: inView ? 1 : 0 },
  })
  let phase
  if (secondary) {
    phase = (
      <PhaseContainer style={spring} secondary>
        <div className="item1">
          <div>
            <h2>{count}</h2>
          </div>
          <div>
            <h2>{name}</h2>
          </div>
        </div>
        <div className="outlinedtxt1">
          <h6>{heroCount}</h6>
        </div>
      </PhaseContainer>
    )
  } else if (tertiary) {
    phase = (
      <PhaseContainer style={spring} tertiary>
        <div className="item1">
          <div>
            <h2>{count}</h2>
          </div>
          <div>
            <h2>{name}</h2>
          </div>
        </div>
        <div className="outlinedtxt1">
          <h6>{heroCount}</h6>
        </div>
      </PhaseContainer>
    )
  } else {
    phase = (
      <PhaseContainer style={spring}>
        <div className="item1">
          <div>
            <h2>{count}</h2>
          </div>
          <div>
            <h2>{name}</h2>
          </div>
        </div>
        <div className="outlinedtxt1">
          <h6>{heroCount}</h6>
        </div>
      </PhaseContainer>
    )
  }
  return (
    <React.Fragment>
      <span ref={ref} />
      {phase}
    </React.Fragment>
  )
}

Phase.defaultProps = {
  secondary: false,
  tertiary: false,
}

Phase.propTypes = {
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  count: PropTypes.string.isRequired,
  heroCount: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
const PhaseContainer = styled(animated.div)`
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-areas: ". ." "count name" ". .";
  max-height: 325px;
  width: 100%;
  div {
    grid-column: 1/-1;
  }
  .item1 {
    place-self: center stretch;
    grid-row: 2;
    height: 100%;
    display: flex;
    place-items: center center;
    /* COUNT AND TITLE CSS*/

    h2 {
      margin: 0 auto;
      padding: 1rem;
      text-align: center;
    }

    /* COUNT SPECIFIC CSS*/

    div:first-of-type {
      flex: 33.33%;
      width: 33.33%;
      float: left;
      z-index: 10;
      background-image: ${props => props.theme.brand.prpteal};
      h2 {
        color: white;
      }
    }
    /* TITLE SPECIFIC CSS*/

    div:nth-of-type(2) {
      flex: 66.66%;
      width: 66.66%;
      float: right;
      background-color: rgba(255, 255, 255, 0.9);
      h2 {
        color: ${props => props.theme.brand.primaryalt};
        z-index: 10;
        text-transform: uppercase;
        place-self: center stretch;
      }
    }
  }

  .outlinedtxt1 {
    grid-row: 1/-1;
    z-index: -1;
    grid-row: 1/-1;
    place-self: center center;
    margin-bottom: ${rhythm(-1)};
    margin-right: ${rhythm(1)};
    h6 {
      font-size: ${rhythm(12.5)};
      margin: 0 auto;
      ${below.s`
            font-size:  ${rhythm(10)};
          `}
      ${below.xs`
          font-size:  ${rhythm(8)};
        `}
    }
  }
  ${props => props.secondary && css``}
  ${props =>
    props.tertiary &&
    css`
      /* COUNT SPECIFIC CSS*/
      .item1 {
        div:first-of-type {
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-image: ${props => props.theme.brand.orangered};
          display: inline-flex;
          background-size: cover;
        }
        div:nth-of-type(2) {
          float: none;
          background: transparent;
          h2 {
            color: ${props => props.theme.brand.primaryalt};
            z-index: 10;
            text-transform: none;
            text-align: left;
            white-space: nowrap;
          }
        }
      }
    `}
`

export default Phase
